import { DataTableCell, Table, TableBody, TableCell, TableHeader } from "@david.kucsai/react-pdf-table";
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import logo from '../../../assets/img/illustrations/paynet.png';
import { currencyFormat } from '../../../helpers/formatters';
import styles from './styles';

const PDFTable = ({ headers, data, header, initInfo }) => {

  return (
    <Document>
      <Page style={styles.container} orientation="landscape">
        {header && (
          <View style={styles.header}>
            <View
              style={{
                ...styles.headerCol,
                ...styles.logoContainer
              }}
            >
              <Image
                style={styles.logo}
                src={logo}
              />
            </View>
            <View
              style={{
                ...styles.headerCol,
                ...styles.companyInfo
              }}
            >
              <Text style={styles.strong}>SU OPERADORA DE LIBRANZA</Text>
              <Text style={styles.strong}>NIT. 900.344.946-3</Text>
              <Text>Calle 13 No. 8-94 Barrio Centro 2do. Piso</Text>
              <Text>Telefono: (605)726 0049</Text>
              <Text>Cel. 301 662 1260</Text>
              <Text>Maicao, La Guajira - Colombia</Text>
            </View>
          </View>
        )}

        {initInfo && [<Table data={initInfo} >
          <TableBody>
            <DataTableCell style={styles.headerLabel} getContent={(r) => r.label} />
            <DataTableCell style={styles.bodyLabel} getContent={(r) => r.value} />
          </TableBody>
        </Table>,
        <View />]}

        <Table data={data} >
          <TableHeader textAlign={"center"}>
            {headers && headers.map(head => (
              <TableCell style={styles.headerLabel}>
                {head.label}
              </TableCell>
            ))}
          </TableHeader>
          <TableBody>
            {data && headers && headers.map(item => (
              <DataTableCell style={styles.bodyLabel} getContent={(r) => {
                return item.type == "money" ? currencyFormat(r[item.key]) : item.ignoreCero && !r[item.key] ? "" : r[item.key]
              }} />
            ))}
          </TableBody>
        </Table>
      </Page>
    </Document>
  )
}

export default PDFTable